<template>
  <div class="pt-24 lg:pt-32 font-mont px-8 lg:px-36 bg-repeat-y bg-cover w-full pb-24"
      style="background-image: url('https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/about-bg.png')">

    <div v-show="showShow" class="fixed inset-0 pt-0 lg:pt-12 font-mont px-0 lg:px-24 w-full bg-black bg-opacity-60 overflow-hidden" style="z-index: 100;">
      <transition name="zoom">
        <template v-if="showShow">
          <work-show :slug="currentSlug" @close="closeShow" />
        </template>
      </transition>
    </div>

    <div class="lg:px-60 border-b-1.5 pb-16 lg:pb-24 border-pc-grey">
      <h2 class="text-center font-pc text-4xl lg:text-5xl">selected <span class="text-pri">works.</span></h2>
      <p class="mt-8 lg:mt-12">
        We build trust-based business relationships and pride ourselves on delivering value-driven results for all our clients. We obsess on how to drive innovative digital solutions to help your business scale and meet your objectives and goals.
      </p>
      <p class="mt-6 lg:mt-8">
        See how we have already helped businesses like yours grow and thrive in the global digital landscape.
      </p>
    </div>

    <div class="mt-2 border-b-1.5 pb-16 lg:pb-24 border-pc-grey">
      <p class="text-sec font-semibold">development</p>

      <div class="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 lg:px-10">
        <!-- <router-link v-for="(item, index) in projects.dev" v-bind:key="index" :to="'/work/' + item.slug">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </router-link> -->
        <div @click="expandShow"
          v-for="(item, index) in projects.dev"
          v-bind:key="index"
          :data-slug="item.slug"
          :to="'/work/' + item.slug">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </div>
      </div>
    </div>

    <div class="mt-2">
      <p class="text-ter font-semibold">consultation</p>

      <div class="mt-10 grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-8 lg:px-10">
        <!-- <router-link v-for="(item, index) in projects.consultation" v-bind:key="index" :to="'/work/' + item.slug">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </router-link> -->
        <div @click="expandShow"
          v-for="(item, index) in projects.consultation"
          v-bind:key="index"
          :data-slug="item.slug"
          :to="'/work/' + item.slug">
          <project-card :title="item.title" :description="item.description" :image="item.cover_image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectCard from '../components/project-card'
  import WorkShow from '../components/work-show'
  export default {
    name: 'Work',
    props: {},
    data() {
      return {
        projects: [],
        showShow: false,
        currentSlug: null
      }
    },
    components: { ProjectCard, WorkShow },
    mounted() {
      this.$api.get('/projects').then(res=> {
        console.log('api projects', res.data.projects)
        this.projects = res.data.projects
      })
    },
    methods: {
      expandShow(e) {
        console.log(e)
        this.currentSlug = e.currentTarget.dataset.slug
        this.showShow = true
      },
      closeShow(e) {
        this.showShow = false
        this.currentSlug = null
      }
    }
  }
</script>

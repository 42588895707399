<template>
  <div class="pt-32 lg:pt-32 lg:fixed relative w-full min-h-screen lg:h-screen bg-no-repeat bg-top bg-cover flex flex-col px-8 lg:px-32 box-border" style="background-image: url('https://pc-portfolio.oss-cn-shanghai.aliyuncs.com/static/services-bg.png')">
    <h2 class="mb-6 lg:mb-0 font-pc text-6xl lg:text-5xl w-full text-center lg:text-left"><span class="text-sec">let's</span> talk.</h2>
    <div class="flex flex-col lg:flex-row">

      <div class="mt-10 lg:mt-24 flex flex-col lg:flex-grow">

        <div class="grid grid-cols-1 gap-10 lg:ml-10">
          <div class="flex items-center">
            <div class="w-12 flex-shrink-0">
              <i class="fas fa-envelope text-4xl lg:text-5xl text-pc-grey-dark"></i>
            </div>
            <p class="ml-6 text-lg sm:text-xl lg:text-lg font-mont">hello ( at ) papercranetech.co</p>
          </div>

          <div class="flex items-center">
            <div class="w-12 flex-shrink-0">
              <i class="fab fa-linkedin text-4xl lg:text-5xl"></i>
            </div>
            <p class="ml-6 text-lg sm:text-xl lg:text-lg font-mont">
              <a href="https://www.linkedin.com/company/papercranetech" class="hover:font-bold" target="_blank">
                papercranetech
              </a>
            </p>
          </div>

          <!-- <div class="flex items-center">
            <div class="w-12 flex-shrink-0">
              <i class="fab fa-facebook-square text-4xl lg:text-5xl" style="color: #1976D2;"></i>
            </div>
            <p class="ml-6 text-lg sm:text-xl lg:text-lg font-mont">
              <a href="https://www.facebook.com/papercranetech" class="hover:font-bold" target="_blank">
                papercranetech
              </a>
            </p>
          </div> -->

          <div class="flex items-center">
            <div class="w-12 flex-shrink-0">
              <i class="fab fa-weixin text-4xl lg:text-5xl" style="color: #4CAF50;"></i>
            </div>
            <p class="ml-6 text-lg sm:text-xl lg:text-lg font-mont">papercranetech</p>
          </div>
        </div>
      </div>

      <div class="flex flex-col w-full lg:w-1/2 lg:ml-20 mb-30 lg:mb-0 mt-20 lg:mt-0 lg:mr-20 lg:pl-10">
        <h2 class="font-pc font-bold text-2xl lg:text-xl w-full text-right mb-4"><span class="text-ter">get in touch.</span></h2>
        <!-- <form action=""> -->
          <div class="grid grid-cols-1 gap-6">

            <div class="flex flex-col w-full">
              <label for="name" class="w-full text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont">Name *</label>
              <input @blur="validateField" name="name" type="text" class="text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont px-2 py-1 rounded-lg w-full border-1.5 box-border border-pc-grey-dark" v-model="form.name" />
            </div>

            <div class="flex flex-col w-full">
              <label for="email" class="w-full text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont">Email address *</label>
              <input @blur="validateField" name="email" type="text" class="text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont px-2 py-1 rounded-lg w-full border-1.5 box-border border-pc-grey-dark" v-model="form.email" />
            </div>

            <div class="flex flex-col w-full">
              <label for="wechat" class="w-full text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont">WeChat ID (Optional)</label>
              <input name="wechat" type="text" class="text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont px-2 py-1 rounded-lg w-full border-1.5 box-border border-pc-grey-dark" v-model="form.wechat" />
            </div>

            <div class="flex flex-col w-full">
              <label for="subject" class="w-full text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont">Subject *</label>
              <input @blur="validateField" name="subject" type="text" class="text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont px-2 py-1 rounded-lg w-full border-1.5 box-border border-pc-grey-dark" v-model="form.subject" />
            </div>

            <div class="flex flex-col w-full">
              <label for="content" class="w-full text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont">What can we help you with? *</label>
              <textarea @blur="validateField" name="content" rows="3" class="text-pc-grey-dark text-sm sm:text-base lg:text-base font-mont px-2 py-1 rounded-lg w-full border-1.5 border-box border-pc-grey-dark" v-model="form.content"></textarea>
            </div>
          </div>

          <div class="flex w-full mt-10 lg:mt-10 mb-10 mb-32 lg:mb-5 justify-end">
            <button @click="contactUs" type="submit" class="w-1/2 bg-pri hover:bg-ter px-4 py-2 text-white text-xl lg:text-lg rounded">
              SUBMIT
            </button>
          </div>
        <!-- </form> -->
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Connect',
  props: {
  },
  data: function() {
    return {
      form: {},
      requiredFields: ['name', 'email', 'subject', 'content']
    }
  },
  methods: {
    validateField(e) {
      const field = e.target.name
      if (!this.form[field]) {
        // console.log('this.form[field] is empty?', this.form[field])
        e.target.classList.remove("border-pc-grey-dark")
        e.target.classList.remove("border-pri")
        e.target.classList.add("border-ter")
      } else {
        e.target.classList.remove("border-pc-grey-dark")
        e.target.classList.remove("border-ter")
        e.target.classList.add("border-pri")
      }
    },
    validateFields() {
      let form = this.form
      let bools = []
      // delete form.wechat
      // console.log({form})
      Object.keys(form).forEach((key) => {
        if (form[key] && key !== 'wechat') bools.push(key)
      })

      // console.log('bools', bools)
      if (this.requiredFields.sort().join(';') == bools.sort().join(';')) {
        return true
      } else {
        return false
      }

    },
    contactUs() {
      // console.log('contact us data', this.form)
      if (this.validateFields()) {
        let data = ''
        Object.keys(this.form).forEach(key => {
          data += (key + ': ' + this.form[key] + '\n')
        })
        console.log('data string', data)
        this.$api.post('slack', { data: {text: data} }).then(res => {
          this.$swal({
            title: "Message sent!",
            text: "We'll get back to you shortly 🚀",
            icon: "success",
            confirmButtonText: "OK",
            confirmButtonColor: "#14A3A1"
          }).then(res => this.form = {})
        })
      } else {
        this.$swal({
          title: "Missing field(s)!",
          text: "Please fill in the mandatory fields",
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#E91A62"
        })
      }

    }
  },
  created() {
    // document.body.style.backgroundColor = '#FFF'
  },
  mounted() {
    console.log('connect mounted')
  }
}
</script>
<style scoped>

</style>

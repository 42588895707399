<template>
  <div class="bg-white px-8 lg:px-32 py-16 lg:py-16 relative overflow-scroll w-full h-full">

    <div :style="{opacity: showX ? 1 : 0}"
      @click="closePopup"
      class="fixed cursor-pointer bg-white shadow-lg lg:shadow-none box-border p-2 lg:p-0 rounded-full top-7 right-5 lg:top-18 lg:right-30 w-12 h-12 transition duration-300"
      style="z-index: 101;">
      <img :src="close" alt="close button" class="opacity-50">
    </div>

    <div class="w-full bg-pri rounded-2xl overflow-hidden bg-cover bg-center relative h-56 lg:h-34rem mt-4 lg:mt-0"
          style="z-index: 1;"
          :style="{backgroundImage: `url(${project.cover_image})`}">
      <div class="absolute inset-0 bg-white bg-opacity-60" style="z-index: -1;"></div>
      <div class="absolute inset-2 lg:inset-4 border lg:border-3 border-white rounded-xl"></div>
      <div class="w-full h-full flex flex-col justify-center items-center z-10">
        <h3 class="text-black text-3xl lg:text-7xl font-pc">{{project.title}}</h3>
        <h5 class="text-sec text-base lg:text-2xl font-semibold">{{project.description}}</h5>
        <!-- <img :src="project.qr" alt="qr code" class="mt-2 lg:mt-8 w-20 lg:w-48"> -->
        <div v-if="project.qr" class="mt-2 lg:mt-8 w-20 lg:w-48 box-border p-2 relative" :class="project.is_qr_circle ? 'rounded-full' : 'rounded-xl'" style="background: #f3f3f3;">
          <img :src="project.qr" alt="qr code" class="w-full h-full">
        </div>
      </div>
    </div>

    <div class="lg:px-44 mt-8 lg:mt-16">
      <p class="text-pri font-semibold lg:text-base">introduction</p>
      <p class="mt-2">{{project.background}}</p>

      <img v-if="project.image" :src="project.image"
        class="mt-8 lg:mt-16 w-full lg:h-22rem rounded-xl lg:rounded-xl object-cover"
        style="box-shadow(0px 0px 20px rgba(0, 0, 0, 0.11));"
        alt="project image">

      <p class="text-ter font-semibold lg:text-base mt-8 lg:mt-16">product</p>
      <p class="mt-2">{{project.solution}}</p>

      <img v-if="project.product_image" :src="project.product_image"
        class="mt-8 lg:mt-16 w-full object-contain"
        style="box-shadow(0px 0px 20px rgba(0, 0, 0, 0.11));"
        alt="project image">

      <div v-if="project.icons && project.icons.length" class="flex items-center justify-center flex-wrap mt-10 lg:mt-16">
        <div v-for="icon in project.icons" class="icon h-20 w-17 lg:h-20 flex">
          <img :src="icon" class="h-full object-contain mx-auto" alt="icon">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import chevron from 'images/chevron_black.svg'
  import chevronPri from 'images/chevron_pri.svg'
  import close from 'images/close.svg'
  export default {
    name: 'WorkShow',
    props: {
      slug: String
    },
    data() {
      return {
        project: {},
        screenH: window.innerHeight,
        showX: false,
        chevron, chevronPri, close
      }
    },
    mounted() {
      console.log('in work-show', this.$route)
      // const slug = this.$route.params.work_slug
      const slug = this.slug
      this.$api.get(`/projects/${slug}`).then(res => {
        console.log({res})
        this.project = res.data.project
        setTimeout(() => this.showX = true, 500)
      })
    },
    methods: {
      closePopup(e) {
        console.log('close popup')
        this.$emit('close')
      }
    }
  }
</script>

<style scoped>
  .icon {
    margin: 1rem 1.5rem;
  }

</style>
